a {
	color: #ffb727;
	text-decoration: none;
}

a:hover {
	color: #ffc85a;
	text-decoration: none;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
	position: absolute;
	z-index: 1000000;
	width: 100%;
	background: black;
}

#header div {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	height: 60px;
}
/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/

.navbar {
	padding: 0;
	margin-left: auto;
}

.navbar ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	align-items: center;
}

.navbar li {
	position: relative;
}

.navbar > ul > li {
	white-space: nowrap;
	padding: 10px 0 10px 28px;
}

.navbar h6,
.navbar h6:focus,
.navbar a,
.navbar a:focus {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 3px;
	font-family: var(--font-secondary);
	font-size: 16px;
	font-weight: 600;
	color: rgba(255, 255, 255, 0.6);
	white-space: nowrap;
	transition: 0.3s;
	position: relative;
}

.navbar h6 {
	margin-bottom: 0px;
}
.navbar h6 i,
.navbar h6:focus i {
	font-size: 14px;
	line-height: 0;
	margin-left: 5px;
}

.navbar > ul > li > h6:before,
.navbar > ul > li > a:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: -6px;
	left: 0;
	background-color: white;
	visibility: hidden;
	width: 0px;
	transition: all 0.3s ease-in-out 0s;
}

.navbar h6:hover:before,
.navbar li:hover > h6:before,
.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
	visibility: visible;
	width: 100%;
}

.navbar h6:hover,
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > h6,
.navbar li:hover > a {
	color: #fff;
	text-decoration: none;
}

.nav {
	position: fixed;
	background: rgba(0, 0, 0, 1);
	color: var(--color-white);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transform: translateX(100%);
	transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
	display: flex;
	justify-content: center;
}

.navbar .dropdown ul {
	display: block;
	position: absolute;
	left: 0;
	top: calc(100% + 30px);
	margin: 0;
	padding: 10px 0;
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
	transition: 0.3s;
	border-radius: 4px;
}

.navbar .dropdown ul li {
	width: fit-content;
	margin-inline: auto;
}

.navbar .dropdown ul a {
	padding: 10px 20px;
	font-size: 14px;
	text-transform: none;
	font-weight: 400;
	color: #3b434a;
	letter-spacing: 1px;
}

.navbar .dropdown ul a i {
	font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
	color: var(--font-secondary);
}

.navbar .dropdown:hover > ul {
	opacity: 1;
	top: 100%;
	visibility: visible;
}

.navbar .dropdown .dropdown ul {
	top: 0;
	left: calc(100% - 30px);
	visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
	opacity: 1;
	top: 0;
	left: 100%;
	visibility: visible;
}

.nav__list {
	list-style: none;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin: 0;
	padding: 0;
}

.nav_list_closed {
	display: none;
}

.nav__link {
	color: inherit;
	font-weight: 900;
	font-size: 1.125rem;
	text-decoration: none;
}

.nav__link:hover,
.nav__link:focus,
.no_hover li:hover,
.no_hover li:focus {
	text-decoration: underline;
	color: white;
}

.no_hover li:hover {
	color: black;
	text-decoration: underline;
}

.dropdown-list-videofoto {
	padding-left: 0px;
	list-style: none;
	text-align: center;
}

.dropdown-list-videofoto li a {
	color: white;
}

.nav-toogle-container {
	margin-left: auto;
	align-self: normal;
}
.nav-toogle {
	padding: 0.5em;
	background: transparent;
	border: 0;
	cursor: pointer;
	position: relative;
	right: 0em;
	top: 0em;
	z-index: 1000;
	vertical-align: middle;
}

.hamburger {
	display: block;
	position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
	background: white;
	width: 2em;
	height: 3px;
	border-radius: 1em;
	transition: transform 250ms ease-in-out;
}

.nav-toogle-container:hover .hamburger,
.nav-toogle-container:hover .hamburger::before,
.nav-toogle-container:hover .hamburger::after {
	background: white;
}

.hamburger::before,
.hamburger::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
}

.hamburger::before {
	top: 6px;
}

.hamburger::after {
	bottom: 6px;
}

.nav_open .nav {
	transform: translateX(0);
}

.nav_open .nav-toggle {
	position: fixed;
}

.nav_open .hamburger {
	transform: rotate(0.625turn);
}

.nav_open .hamburger::before {
	transform: rotate(90deg) translateX(-6px);
}

.nav_open .hamburger::after {
	opacity: 0;
}

@media (max-width: 992px) {
	.nav__link i {
		display: none;
	}
	.no_hover h6:hover {
		color: white;
		text-decoration: none;
	}
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
	width: 100vw;
	height: 100vh;
	background: url("./media/hero_bcg.jpg") left 18% top 10%;
	background-size: cover;
	position: relative;
	padding: 0px;
}

#hero:before {
	content: "";
	background: rgba(0, 0, 0, 0.4);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#hero .hero-container {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

#hero h1 {
	margin: 0 0 10px 0;
	font-size: 64px;
	font-family: "Raleway", sans-serif;
	color: #fff;
	text-align: center;
}

#hero .btn-scroll {
	transition: 0.4s;
	color: rgba(255, 255, 255, 0.6);
	animation: up-down 1s ease-in-out infinite alternate-reverse both;
}

#hero .btn-scroll i {
	font-size: 48px;
	transition: 0.4s;
}

#hero .btn-scroll:hover i {
	color: white;
}

.logo-header {
	align-self: normal;
}
.text-logo {
	font-size: 18px;
}

/* @media (min-width: 1024px) {
	#hero {
		background-attachment: fixed;
	}
} */

@media (max-width: 575px) {
	#hero h1 {
		font-size: 40px;
	}

	#hero h2 {
		text-align: center;
		margin-bottom: 30px;
	}
}

@-webkit-keyframes up-down {
	0% {
		transform: translateY(5px);
	}

	100% {
		transform: translateY(-5px);
	}
}

@keyframes up-down {
	0% {
		transform: translateY(5px);
	}

	100% {
		transform: translateY(-5px);
	}
}

/*! Sections General */

section {
	padding: 60px 0;
}

.section-bg {
	background-color: white;
}

.section-title {
	text-align: center;
	padding: 30px 0;
	position: relative;
}

.section-title h2 {
	font-size: 32px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 20px;
	padding-bottom: 0;
	color: #3b434a;
	position: relative;
	z-index: 2;
}

.section-title span {
	position: absolute;
	top: 30px;
	color: #eef0f2;
	left: 0;
	right: 0;
	z-index: 1;
	font-weight: 700;
	font-size: 52px;
	text-transform: uppercase;
	line-height: 0;
}

.section-title p {
	margin-bottom: 0;
	position: relative;
	z-index: 2;
}

@media (max-width: 575px) {
	.section-title h2 {
		font-size: 28px;
		margin-bottom: 15px;
	}

	.section-title span {
		font-size: 38px;
	}
}

/*! General structure page */

.initial-description-page {
	background-color: black;
	color: white;
	padding: 2rem 2rem;
	text-align: center;
	line-height: 2rem;
}
.initial-description-page h2 {
	font-size: 36px;
	letter-spacing: 3px;
	font-weight: 500;
}

.initial-description-page h6 {
	margin-bottom: 0px;
}
.initial-description-page h6 p:last-child {
	margin-bottom: 0px;
}
.initial-description-page p {
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 2rem;
}

@media (min-width: 576px) {
	.initial-description-page {
		padding: 2rem 4rem;
	}
}

.first-element {
	padding-top: 60px;
}

/*! Foto ADV */

/* .gallery-foto {
	grid-column-gap: var(--column-gutter);
	grid-row-gap: var(--row-gutter);
	align-items: start;
	display: grid;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	padding: 12px;
}

.gallery-foto__column {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	row-gap: var(--row-gutter);
}

@media (max-width: 768px) {
	.gallery-foto {
		grid-column-gap: var(--column-gutter);
		align-items: start;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
} */

.gallery-photo,
.vertical-photo-home,
.video-scacchiera-home,
.video-scacchiera-social {
	column-count: 3;
	margin: 0 auto;
	padding: 12px;
}

.gallery-photo img,
.vertical-photo-home img,
.video-scacchiera-home .video,
.video-scacchiera-social .video {
	display: block;
	padding: 6px 0;
	box-sizing: border-box;
}

.video-scacchiera-home .video,
.video-scacchiera-social .video {
	display: flex;
	position: relative;
	overflow: hidden;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.video-scacchiera-home iframe,
.video-scacchiera-social iframe {
	position: absolute;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 992px) {
	.video-scacchiera-home,
	.video-scacchiera-social {
		column-count: 2;
	}
}
@media screen and (max-width: 768px) {
	.gallery-photo {
		column-count: 2;
	}
}

@media screen and (max-width: 576px) {
	.vertical-photo-home,
	.video-scacchiera-home,
	.video-scacchiera-social {
		column-count: 1;
	}
}

/*! VIDEO ADV */

#videoADV .container-video,
#videoCorporate .container-video {
	padding-top: 2rem;
}

#videoADV .video-adv-client-section,
#videoCorporate .video-corporate-client-section {
	display: flex;
	flex-direction: column;
	align-items: center;
}
#videoADV .video-adv-client-section:not(:first-child),
#videoCorporate .video-corporate-client-section:not(:first-child) {
	margin-top: 2rem;
}

.video-adv-client-section h2,
.video-corporate-client-section h2 {
	color: black;
	margin-bottom: 1rem;
}

@media (min-width: 992px) {
	.video-adv-client-section .video,
	.video-corporate-client-section .video {
		padding-inline: 2rem;
	}

	#videoADV .video-adv-client-section:not(:first-child),
	#videoCorporate .video-corporate-client-section:not(:first-child) {
		margin-top: 3rem;
	}

	#videoADV .container-video,
	#videoCorporate .container-video {
		padding-top: 6rem;
	}
}

/*! EVENT  */

#event .single-event-section,
#fotoCorporate .single-event-section {
	padding-top: 6rem;
	margin-inline: auto;
}

#event .foto-event-section {
	align-self: self-end;
}

#event .single-event-section h2 {
	text-align: center;
}

#event .single-event-section .d-flex {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
}

#event .video {
	display: flex;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 15em;
	align-items: center;
	justify-content: center;
}

#event iframe {
	position: absolute;
	width: 100%;
	height: 100%;
}

@media (min-width: 480px) {
	#event .video {
		height: 290px;
	}
}
@media (min-width: 576px) {
	#event .video {
		height: 325px;
	}
}
@media (min-width: 768px) {
	#event .video {
		height: 400px;
	}
}

@media (min-width: 992px) {
	#event .single-event-section .d-flex {
		flex-direction: row;
	}

	#event .single-event-section .d-flex.even {
		flex-direction: row-reverse;
	}

	#event .foto-event-section {
		align-self: center;
	}
}
/*! FOTO CORPORATE */

#fotoCorporate .single-event-section,
#fotoCorporate .single-event-section.even {
	flex-direction: column-reverse;
}

@media (min-width: 768px) {
	#fotoCorporate .single-event-section {
		flex-direction: row;
	}
	#fotoCorporate .single-event-section.even {
		flex-direction: row-reverse;
	}
}

/*! SOCIAL */
/*--------------------------------------------------------------
# Social
--------------------------------------------------------------*/

.social-single-item {
	padding: 8px 0px;
}
.video-scacchiera-social {
	margin-top: 40px;
}

/*
@media (min-width: 576px) {
	.social-single-item {
		padding: 20px 5px 0 5px;
	}
}

@media (min-width: 992px) {
	.social-single-item {
		padding: 35px 10px 0 10px;
	}
}  */

/*! ABOUT */
#about {
	background-color: black;
}

#about .section-title h2 {
	color: white;
}

#about .section-title span {
	color: #464646;
}

#about .content {
	padding-bottom: 0px;
}
#about .content p {
	color: white;
	text-align: center;
	padding: 2rem 0 0 0;
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 2rem;
	margin-bottom: 0px;
}

@media (min-width: 992px) {
	#about .content p {
		padding: 0rem 4rem;
		margin-bottom: 0px;
	}
}

@media (min-width: 992px) {
	#about .content {
		padding-bottom: 20px;
	}
}

/*! CONTACT*/

/*--------------------------------------------------------------
# Contact Me
--------------------------------------------------------------*/

section#contact {
	padding: 60px 0 0 0;
}

.contact .info-box {
	color: #464646;
	text-align: center;
	border: 1px solid rgba(214, 215, 216, 0.6);
	box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
	border-radius: 4px;
	background-color: #fff;
}

.contact .info-box.channels {
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.contact .info-box h3 {
	font-size: 20px;
	color: #777777;
	font-weight: 700;
	margin: 10px 0;
}

.contact .info-box .description {
	padding: 2rem 4rem;
	line-height: 2rem;
	font-size: 16px;
	margin-bottom: 0;
}
.contact .info-box p {
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	margin-bottom: 0;
}

.contact .info-box i {
	font-size: 20px;
}

.contact .social-links {
	display: flex;
	justify-content: center;
}

.contact .social-links a {
	font-size: 18px;
	display: inline-block;
	color: rgba(0, 0, 0);
	margin: 0 8px;
	transition: 0.3s;
}

.contact .social-links i,
.contact .info-box.location i {
	position: relative;
	top: 3px;
}

.contact .social-links a:hover {
	color: rgba(0, 0, 0, 0.5);
}

.contact .info-box.location {
	padding: 20px 27px;
	align-items: center;
}

.contact .info-box.location p {
	font-size: 1.125rem;
}

.contact .email-form {
	padding: 27px;
	border: 1px solid rgba(214, 215, 216, 0.6);
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
}

.contact .form-group {
	position: relative;
}
.contact .form__label {
	position: absolute;
	top: 0.6rem;
	font-size: 0.9rem;
	left: 8px;
	color: #777;
	cursor: text;
	padding: 0 0.5rem;
	transition: top 200ms ease-in, font-size 200ms ease-in, color 200ms ease-in;
	background-color: var(--color-white);
}

.contact .form__label.extra-padding {
	left: 20px;
}

.form-control:focus ~ .form__label,
.form-control:not(:placeholder-shown).form-control:not(:focus) ~ .form__label {
	color: #86b7fe;
	top: -0.6rem;
	font-size: 0.8rem;
}

.contact .email-form input,
.contact .email-form textarea {
	border-radius: 4px;
	box-shadow: none;
	font-size: 14px;
}

.contact .email-form input:focus,
.contact .email-form textarea:focus {
	border-color: #86b7fe;
}

.contact .email-form input {
	padding: 10px 15px;
}

.contact .email-form textarea {
	padding: 12px 15px;
}

.contact .email-form button[type="submit"] {
	background: black;
	border: 1px solid #dee2e6;
	padding: 10px 24px;
	color: #eef0f2;
	transition: 0.4s;
	border-radius: 5px;
}

.contact .email-form button[type="submit"]:hover {
	background: #eef0f2;
	color: black;
}

.contact .email-form button[type="submit"]:focus {
	background: #86b7fe;
	color: white;
}

.contact .personal-data-link {
	color: black;
	font-weight: bold;
}

.contact .personal-data-link:hover {
	color: rgba(0, 0, 0, 0.7);
	text-decoration: underline;
}

/*! FOOTER */

#footer {
	background-color: black;
	color: #fff;
	font-size: 14px;
	text-align: center;
	padding: 40px 0;
	position: relative;
	margin-top: 6rem;
}

#footer h3 {
	font-size: 36px;
	font-weight: 700;
	color: #fff;
	position: relative;
	padding: 0;
	margin: 0 0 5px 0;
}

#footer #footer .copyright {
	color: rgba(255, 255, 255, 0.6);
}

#footer .company-info {
	margin: 20px 0px 5px 0;
}

#footer .company-info p {
	font-size: 14px;
	font-style: italic;
	color: rgba(255, 255, 255, 0.6);
	padding: 0;
	margin-bottom: 0px;
}
