/* Fonts */
:root {
	--font-default: "Raleway", sans-serif, "Open Sans", system-ui, -apple-system,
		"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
	--font-primary: "Montserrat", sans-serif;
	--font-secondary: "Poppins", sans-serif;
}

/* Colors */
:root {
	--color-default: #222222;
	/* --color-primary: #ff7507; */
	--color-primary: #974f08;
	--color-secondary: #ba620b;
	--color-secondary-half: rgba(18, 154, 251, 0.5);
	--color-tertiary: #693400;
	--color-black: #000000;
	--color-white: #fff;
}

:root {
	--column-gutter: 12px;
	--row-gutter: 12px;
	--columns: 3;
}
/* Smooth scroll behavior */
:root {
	scroll-behavior: smooth;
}

body {
	font-family: "Raleway", sans-serif;
	color: #444444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
	position: fixed;
	inset: 0;
	z-index: 999999;
	overflow: hidden;
	background: #fff;
	transition: all 0.6s ease-out;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #fff;
	border-color: var(--color-black) transparent var(--color-black) transparent;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1.5s linear infinite;
	animation: animate-preloader 1.5s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
